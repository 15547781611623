import React from 'react'
import Layout from '../components/layout'
import Home from './home/index'

export default class IndexPage extends React.Component {
  render() {
    return (
      <Layout transparent>
        <Home/>
      </Layout>
    )
  }
}
